@each $key, $breakpoint in $breakpoints {
	@media screen and (max-width: $breakpoint) {
		button.p-button.label-#{$key}-none,
		p-button.label-#{$key}-none {
			.p-button-label {
				display: none !important;
			}
			.p-button-icon {
				margin: 0;
			}
		}
	}
}
.p-button {
	min-width: unset;
}

.p-button.p-button-text:not(:disabled):hover {
  background: rgba(63, 81, 181, 0.04);
  color: var(--primary-color);
  border-color: transparent;
}

.p-button.p-button-text:not(:disabled):active, .p-button.p-button-outlined:not(:disabled):active {
  background: rgba(63, 81, 181, 0.16);
}

.p-button.p-button-xs {
	font-size: 0.5rem;
	padding: 0.5rem 0.25rem;
}

.p-button.p-button-xs.p-button-icon-only {
	padding: 0.25rem 0.25rem;
	min-width: 2rem;
}

.p-button.p-button-xs .p-button-icon {
	font-size: 0.875rem;
}

.p-button {
	padding: 0.75rem 1rem;
}

.p-button.p-button-icon-only {
	// width: 2.5rem;
	padding: 0.75rem 0.25rem;
}

.p-button-xs,
.p-button.p-button-icon-only.p-button-xs {
	width: 2rem;
	height: 2rem;
}

.p-selectbutton .p-button {
	width: auto;
}

@keyframes button-blink {
	0% {
		background-color: transparent;
		color: var(--primary-color);
		border-color: transparent;
	}
	100% {
		color: var(--primary-color-text);
		background: var(--primary-color);
		border: 1px solid transparent;
	}
}


.button-blink {
	animation-name: button-blink;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-direction: alternate;
}


.button-strike::before {
	content: '';
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	right: 0.5rem;
	bottom: 0.5rem;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%"><line x1="0" y1="0" x2="100%" y2="100%" style="stroke:rgb(255,61,50);stroke-width:2" stroke-dasharray="0" /></svg>');
}

.p-button.large-icon .p-button-icon.pi {
	font-size: 1rem;
}

.button-nowrap .p-button-label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
