input[required],
input[ng-reflect-required="true"],
textarea[ng-reflect-required="true"],
p-dropdown[ng-reflect-required="true"] input.p-dropdown-label {
	&::placeholder,
	&::-moz-placeholder,
	&:-moz-placeholder,
	&::-ms-input-placeholder,
	&::-webkit-input-placeholder {
		color: var(--color-error);
	}
}

input:required::placeholder,
textarea:required::placeholder,
p-dropdown[ng-reflect-required="true"] .p-dropdown-label.p-placeholder {
	color: var(--color-error);
	opacity: 0.75;
}

.p-inputtext:read-only:hover,
.p-inputtext:read-only:focus {
	// border-color: #ced4da;
	box-shadow: none;
}
.p-calendar-readonly,
.p-inputtext:read-only {
	border-top: 1px solid transparent !important;
	border-left: 1px solid transparent !important;
	border-right: 1px solid transparent !important;
	border-radius: 0;
	opacity: 1 !important;
}
