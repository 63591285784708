body:before {
	content: "preprod";
	position: fixed;
	bottom: 0;
	left: 0;
	display: block;
	padding: 0.5rem 0.5rem;
	background: #000;
	border: 1px solid blue;
	color: #fff;
	border-radius: 0 5px 0 0;
	opacity: 0.8;
	z-index: 1;
}
