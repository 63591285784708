td, th,
.p-datatable-scrollable td.p-frozen-column {
	background: var(--surface-card);
}

.table-full-w table {
	width: 100%;
}

.p-datatable.table-auto table {
	width: auto;
	min-width: auto;
	margin-left: auto;
	margin-right: auto;
}

$colDisplayNumberWidth: 100px;
$colInputNumberWidth: 100px;

.p-treetable.p-datatable-gridlines tr th,
.p-treetable.p-datatable-gridlines tr td {
	border-width: 1px !important;
}

.p-treetable.p-datatable-gridlines tfoot tr > th,
.p-treetable.p-datatable-gridlines tfoot tr > td {
	border: 1px solid var(--border-color);
}

.p-treetable.p-datatable-gridlines tfoot tr > td,
.p-treetable.p-datatable-gridlines tfoot tr > th,  {
	font-weight: normal;
}

.p-treetable.p-treetable-sm tfoot tr > td,
.p-treetable.p-treetable-sm tfoot tr > th {
	padding: 0.5rem 0.5rem;
}

.table-hover .p-datatable-unfrozen-view > .p-datatable-scrollable-body tr > td,
.table-hover .p-datatable-unfrozen-view > .p-datatable-scrollable-body tr > th,
.table-hover .p-datatable-tbody > tr:not(.p-highlight) > td,
.table-hover .p-datatable-tbody > tr:not(.p-highlight) > th,
.table-hover .p-treetable-tbody > tr:not(.p-highlight) > td,
.table-hover .p-treetable-tbody > tr:not(.p-highlight) > th,
.table-hover .p-treetable-tfoot > tr:not(.p-highlight) > td,
.table-hover .p-treetable-tfoot > tr:not(.p-highlight) > th {
	transition: border-color 500ms;
}

.table-hover .p-datatable-unfrozen-view > .p-datatable-scrollable-body tr:hover > td,
.table-hover .p-datatable-unfrozen-view > .p-datatable-scrollable-body tr:hover > th,
.table-hover .p-datatable-tbody > tr:not(.p-highlight):hover > td,
.table-hover .p-datatable-tbody > tr:not(.p-highlight):hover > th,
.table-hover .p-treetable-tbody > tr:not(.p-highlight):hover > td,
.table-hover .p-treetable-tbody > tr:not(.p-highlight):hover > th,
.table-hover .p-treetable-tfoot > tr:not(.p-highlight):hover > td,
.table-hover .p-treetable-tfoot > tr:not(.p-highlight):hover > th {
	border-bottom-color: var(--primary-color-focus) !important;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
	width: 2rem;
	height: 2rem;
}

tr > th.cell-input,
tr > td.cell-input {
	height: 2.5rem;

	&:hover {
		// cursor: pointer;
	}

	// &.cell-input > *,
	.input-container {
		max-width: 100%;
	}

	p-celleditor {
		max-width: 100%;
	}

	input {
		margin-top: -1px;
		margin-bottom: -1px;
		max-width: 100%;
		width: 100px;
	}

	.p-inputtext {
		// padding-top: 0.1rem;
		// padding-bottom: 0.1rem;
		padding-top: 1px;
		padding-bottom: 1px;
	}

}

.p-datatable .p-datatable-loading-overlay {
	z-index: 20 !important;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
	background: var(--surface-200);
}

.col-rang {
	min-width: 50px;
	width: 50px;
	max-width: 50px;
}

tr.disabled,
tr.disabled td,
tr.disabled td.p-frozen-column,
tr.readonly,
tr.readonly td,
tr.readonly td.p-frozen-column,
td.readonly,
td.disabled,
tfoot td.disabled,
.p-treetable-tfoot > tr > td.disabled {
	background: var(--surface-100) !important;
}

.invalid-cell {
	background: var(--red-200) !important;
	// color: #fff !important;
}

.validation-animateur-manquante {
	td {
		background: var(--validation-animateur-manquante);
	}
}

.validation-magasin-manquante {
	td {
		background: var(--validation-magasin-manquante);
	}
}


.clear-cell {
	background: transparent !important;
	border: none !important;
}

.cell-th {
	border: 1px solid var(--border-color);
	font-weight: 600;
}

table th.nowrap,
table td.nowrap {
	white-space: nowrap;
	width: 1px;
}

.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-virtual-scrollable-body > .cdk-virtual-scroll-content-wrapper > .p-datatable-table > .p-datatable-thead,
th.p-frozen-column {
	z-index: 10 !important;
}

td.p-frozen-column {
	z-index: 2;
}

@keyframes evenement-updated {
	from {
		background-color: var(--yellow-300);
	}
}

td.depassement-objectif {
	background-color: var(--depassement-objectif) !important;
}

activite-magasin {

	tr.eve-traite td,
	tr.eve-traite td.p-frozen-column {
		background-color: var(--eve-traite);
	}

	tr.eve-masque td,
	tr.eve-masque td.p-frozen-column {
		background-color: var(--pink-100);
	}

	tr.eve-attente td,
	tr.eve-attente td.p-frozen-column {
		background-color: var(--eve-attente) !important;
		transition: background-color 500ms linear !important;
	}

	tr.eve-updated td,
	tr.eve-updated td.p-frozen-column {
		animation: evenement-updated 3s linear;
		animation-iteration-count: 1;
	}

	tr.eve-deleted td,
	tr.eve-deleted td.p-frozen-column {
		background-color: var(--surface-500) !important;
		transition: background-color 500ms linear !important;
	}

}

th.current-month,
td.current-month {
	box-shadow: 0px 0px 1000px var(--primary-soft) inset;
}

.p-datatable .p-datatable-tbody > tr.row-total th,
.p-datatable .p-datatable-tbody > tr.row-total td {
	background: var(--surface-100);
}

reporting-activite {
	.p-datatable .p-datatable-tbody > tr.row-total > th:first-of-type,
	.p-datatable .p-datatable-tbody > tr.row-total > td:first-of-type,
	.p-datatable .p-datatable-tbody > tr.row-region > th:first-of-type,
	.p-datatable .p-datatable-tbody > tr.row-region > td:first-of-type {
		font-weight: 600;
	}
}

.table-resultats {

	.p-treetable-scrollable-body > div {
		display: none;
	}

	.p-treetable-frozen-view .p-treetable-scrollable-body .row-indicateur td,
	.p-treetable-frozen-view .p-treetable-scrollable-body .row-separator td {
		padding-left: 25px;
	}

	.p-treetable-scrollable-body {
		overflow-x: scroll !important;
	}

	tr.n-1 th,
	tr.n-1 td {
		background: var(--primary-soft);
	}

}

tr.row-separator th,
tr.row-separator td,
.p-treetable.p-datatable-gridlines tfoot tr.row-separator th,
.p-treetable.p-datatable-gridlines tfoot tr.row-separator td, {
	background: var(--surface-200);
	text-align: left;
	height: 2rem;
	font-size: 0.75rem;
	font-weight: bold !important;
}

.table-objectifs,
.table-resultats {
	max-width: 1617px;
}

th.col-indicateur,
td.col-indicateur,
colgroup col.col-indicateur {
	min-width: $colDisplayNumberWidth !important;
	width: $colDisplayNumberWidth !important;
	// max-width: $colDisplayNumberWidth !important;
}

th.col-mois,
td.col-mois,
colgroup col.col-mois {
	min-width: $colDisplayNumberWidth !important;
	width: $colDisplayNumberWidth !important;
	max-width: $colDisplayNumberWidth !important;
}

th.col-total,
td.col-total,
colgroup col.col-total {
	min-width: 100px !important;
	width: 100px !important;
}

.table-objectifs,
.table-resultats,
.table-declarations,
.table-reporting-activite,
.table-qui-fait-quoi {

	.p-treetable-scrollable-body,
	.p-treetable-frozen-view {
		border-right: 1px solid #e9ecef;
	}

	/* force scrollable table width based on predefined column width and number of columns, to avoid using a width:auto because chrome... */
	@for $i from 1 through 13 {
		.table-saisie-cols-#{$i} .p-treetable-scrollable-view.p-treetable-unfrozen-view table {
			width: $i * $colDisplayNumberWidth;
		}

		.p-treetable-scrollable-view.p-treetable-unfrozen-view table {
			width: $i * $colDisplayNumberWidth;
		}
	}

	.row-group td,
	.row-group th,
	.row-group td.p-frozen-column,
	.row-group th.p-frozen-column,
	tfoot .row-group td,
	tfoot .row-group th {
		height: 3.5rem;
	}

	.row-restant td,
	.row-ca td,
	tfoot .row-ca td,
	tfoot .row-ca th {
		height: 2.5rem;
	}

	.row-restant td {
		font-style: italic;
	}

	.row-child td,
	.row-vendeur td,
	.row-indicateur td,
	tfoot .row-indicateur td,
	tfoot .row-indicateur th {
		height: 2.5rem;
	}

}

.table-objectifs,
.table-resultats,
.table-declarations,
.table-reporting-activite {

	.row-group td,
	.row-group th,
	.row-group td.p-frozen-column,
	.row-group th.p-frozen-column,
	tfoot .row-group td,
	tfoot .row-group th {
		background: var(--surface-300) !important;
	}
}


.table-declarations {

	.p-treetable-scrollable-body,
	.p-treetable-frozen-view {
		border-right: 1px solid #e9ecef;
	}

	th.col-indicateur,
	td.col-indicateur,
	colgroup col.col-indicateur {
		min-width: 120px !important;
		width: 120px !important;
	}

	th.col-action,
	td.col-action,
	colgroup col.col-action {
		min-width: 100px !important;
		width: 100px !important;
	}

	.row-child td {
		height: 3.6rem;
	}
}


.col-label {
	min-width: 150px !important;
	width: 150px !important;
}


indicateurs-digital {
	.logo {
		height: 2rem;
		width: 5rem;
	}
}


atterrissage-ca-magasin {

	th, td {
		// position: relative;
		// z-index: -1;
	}

}


.p-datatable .p-datatable-tfoot > tr.row-primary td,
.row-primary td {
	background: var(--orange-100);
	color: #000;
}

.p-datatable .p-datatable-tfoot > tr.row-secondary td,
.row-secondary td {
	background: var(--blue-200);
	color: #000;
}

.p-datatable .p-datatable-tfoot > tr.row-success td,
.row-success td {
	background: var(--green-200);
	color: #000;
}

.p-datatable .p-datatable-tfoot > tr.row-warning td,
.row-warning td {
	background: var(--yellow-500);
	color: #000;
}

.p-datatable .p-datatable-tfoot > tr.row-error td,
.row-error td {
	background: var(--red-200);
	color: #000;
}

td.cell-white {
	background: var(--surface-card) !important;
	color: #000;
}

tr.row-darker td,
tr.row-darker th,
th.cell-darker,
td.cell-darker {
	position: relative;
}

tr.row-darker td::before,
tr.row-darker th::before,
th.cell-darker::after,
td.cell-darker::after {
	display: block;
	@include absolute-full;
	content: '';
	background: rgb(0,0,0,0.2) !important;
	margin: 0 -1px -1px 0;
}

th.cell-primary,
td.cell-primary {
	background: var(--orange-100) !important;
	color: #000;
}

th.cell-secondary,
td.cell-secondary {
	background: var(--blue-200) !important;
	color: #000;
}

th.cell-success,
td.cell-success {
	background: var(--green-200) !important;
	color: #000;
}

th.cell-warning,
td.cell-warning {
	background: var(--yellow-500) !important;
	color: #000;
}

th.cell-error,
td.cell-error {
	background: var(--red-200) !important;
	color: #000;
}

th.cell-white,
td.cell-white {
	background: var(--surface-card) !important;
	color: #000;
}

th.cell-white,
td.cell-white {
	background: var(--surface-card) !important;
	color: #000;
}

th.cell-black,
td.cell-black {
	background: var(--surface-900) !important;
	color: #fff !important;
}

th.bg-primary-soft,
td.bg-primary-soft,
.row-group th.bg-primary-soft,
.row-group td.bg-primary-soft {
	background: var(--primary-soft) !important;
}

.cell-rotate-left {
	-ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
}


.p-treetable-frozen-view cdk-virtual-scroll-viewport {
	overflow: hidden;
}

.border-left-width-thick {
	border-left-width: 3px !important;
}

.table-w-full .p-datatable,
.table-w-full table {
	width: 100%;
}

.p-rowgroup-header td {
	position: relative;
	top: -1px;
}
