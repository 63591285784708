.app-menu {
	background-color: var(--app-menu-background);
}

.app-menu .logo-enseigne {
	height: 42px;
	width: 100px;
}

.app-menu .p-menubar:not(.p-menubar-mobile-active) >	gz-menubarsub,.app-menu .p-menubar > .p-menubar-button {
	position: relative;
}

.p-menubar .p-menubar-root-list > .p-menuitem .p-menuitem-link.p-menuitem-link-active {
	background: var(--primary-color-active);
	color: var(--primary-color-text);
}
.p-menubar .p-menuitem-link-active .p-menuitem-text {
	color: var(--primary-color-text);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-menuitem-link-active:hover {
	background: var(--primary-color-hover) !important;
}

.p-menubar .p-menuitem-text {
	font-weight: 400;
}

.p-menubar .p-menuitem-link-active .p-menuitem-text {
	font-weight: bold;
}

.p-menubar .p-menuitem .p-menuitem-link:focus {
	background: none;
}

gz-menubar .p-menubar {
	border-radius: 0;
	border-top: 0;
	border-left: 0;
	border-right: 0;
}

.app-menu {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
}

.p-menubar .p-submenu-list {
	width: auto;
}

.p-menubar .p-submenu-list .p-menuitem {
	width: max-content;
	min-width: 100%;
	max-width: 18rem;
}

.p-menubar .p-menuitem-link {
	justify-content: space-between;
}

.p-menubar .p-submenu-list .p-menuitem-link .p-submenu-icon {
	margin-left: 0.5rem !important;
}
