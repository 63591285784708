html {
	height: 100%;
	font-size: 14px;
}

@mixin absolute-full {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

body {
	padding: 0 !important;
	margin: 0 !important;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 1rem;
	background-color: var(--surface-ground);
	color: var(--text-color);
	min-height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include absolute-full;
}

.absolute-full {
	@include absolute-full;
}

* {
	min-height: 0;
	min-width: 0;
}

ui-view,
root,
.app-content {
	display: block;
	height: 100%;
	position: relative;
}

.app-content {
	padding: 6rem 1.5rem 1.5rem;
}

.app-content ui-view > * {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
}

.min-table-height {
	min-height: 300px;
}

.p-dialog-content > * {
	position: relative;
	display: block;
	height: 100%;
}

@media screen and (max-width: 576px) {
	.app-content {
		padding: 6rem 0 1.5rem;
	}
}

/* masquer les spinner sur input number */
input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

a {
	text-decoration: none;
	font-weight: 600;
	color: var(--primary-color)
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
}

h1:empty, h2:empty, h3:empty, h4:empty, h5:empty, h6:empty {
	display: none;
	margin: 0;
}

.secondary-color {
	color: var(--secondary-color);
}

.text-error,
.text-danger {
	color: var(--color-error) !important;
}

.align-bottom {
	vertical-align: bottom;
}

.p-inputtext {
	padding: 0.75rem;
}

.p-input-icon-right > .p-inputtext {
	padding-right: 1.3rem;
}

.p-input-icon-right > i:last-of-type {
	right: 0.5rem;
}

.ff-reset {
	font-family: inherit;
}

.p-field-checkbox {
	padding: 0.75rem;

	label {
		margin-left: 0.5rem;
	}

}

.p-field-checkbox:not(.p-checkbox-disabled) label {
	cursor: pointer;
}

.p-knob svg {
	max-width: 100%;
	max-height: 100%;
}

.squareit::after {
	content: "";
	display: block;
	padding: 100%;
}

.p-fluid .p-calendar .p-inputtext {
	// min-width: 190px;
}

p-slider.ng-invalid .p-slider-handle {
	background-color: var(--color-error);
}

p-rating.ng-invalid .p-rating-icon {
	color: var(--color-error);
}

p-toast {
	position: relative;
	z-index: 10000;
}

.p-toast-icon-close {
	flex-shrink: 0;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
	max-height: 300px;
	overflow-y: auto;
	max-width: 250px;
	overflow-x: hidden;
}

.p-toast-message-icon {
	flex-shrink: 0;
}

indicateur-tooltip {
	height: 1rem;
}

.tooltip-large {
	max-width: 20rem;
}

.height-6rem {
	height: 6rem;
}

.middle {
	vertical-align: middle;
}


.p-dialog {
	max-width:	100%;
}

.link {
	cursor: pointer;
	color: var(--primary-color);
}

.link:hover {
	text-decoration: underline;
}

.bg-wizville {
	background: var(--wizville) !important;
}

.bg-google {
	background: var(--google) !important;
}

.bg-primary-soft {
	background: var(--primary-soft) !important;
}

.label {
	margin-bottom: 0.5rem;
}

number-display {
	font-family: Arial !important;
}


.text-success {
	color: #689F38;
}

pre {
	font-family: inherit;
	margin: 0;
}

.white-space-pre {
	white-space: preserve-breaks;
}

p-dropdown .selector-item.disabled,
p-dropdownitem .selector-item.disabled {
	color: var(--disabled-color);
}

p-dropdown .selected-item.disabled {
	color: var(--disabled-color);
	text-decoration: line-through;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.inactive-text {
	color: grey;
}

.p-inline-message-icon {
  flex-shrink: 0;
  align-self: start;
}

.p-toast-detail,
.p-inline-message-text {
	white-space: preserve-breaks;
}

.p-message-no-icon .p-inline-message-icon {
	display: none;
}
