.presence-slider.p-slider.p-slider-horizontal {
	height: 10px;
	border-radius: 0;
}

.presence-slider.p-slider .p-slider-handle {
}

.declaration-panel .p-panel-content {
	background: var(--surface-200);
	padding-top: 1rem;
}

.presence-slider.stripe-4::after {
	content: "";
	width: 100%;
	height: 100%;
	display: block;
	background: transparent repeating-linear-gradient(
		to right,
		#fff, #fff 1px,
		transparent 1px, transparent calc(100% / 4)
	);
}

.presence-slider .p-slider-range::after {
	content: "";
	width: 100%;
	height: 100%;
	display: block;
}

.presence-slider.stripe-4.selected-2 .p-slider-range::after {
	background: transparent repeating-linear-gradient(
		to right,
		#fff, #fff 1px,
		transparent 1px, transparent calc(100% / 2)
	);
}

.presence-slider.stripe-4.selected-3 .p-slider-range::after {
	background: transparent repeating-linear-gradient(
		to right, #fff, #fff 1px,
		transparent 1px, transparent calc(100% / 3)
	);
}

.presence-slider.stripe-4.selected-4 .p-slider-range::after {
	background: transparent repeating-linear-gradient(
		to right,
		#fff, #fff 1px,
		transparent 1px, transparent calc(100% / 4)
	);
}
