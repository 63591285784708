.show-print {
	display: none;
}

/* pas besoin de forcer la dimension de page, le navigateur s'en chargera */
// @page {
// 	width: 21cm;
// 	height: 29.7cm;
// 	max-width: 21cm;
// 	max-height: 29.7cm;
// }


@media print {

	html {
		font-size: 7pt;
		// font-size: 100%;
	}

	body {
		width: calc(100%);
		height: calc(100%);
		margin: auto !important;
		// border: 1px solid red;
		background: #fff;
		-webkit-print-color-adjust:exact !important;
		print-color-adjust:exact !important;
		color-adjust: exact !important;
		position: relative;
	}

	body::before {
		display: none !important;
	}

	* {
		// font-family: Roboto !important;
	}

	.app-content {
		max-width: 100%;
		max-height: 100%;
		margin: auto;
		padding: 0;
		// border: 1px solid blue;
	}

	.show-print {
		display: unset;
	}

	.hide-print {
		display: none !important;
		visibility: hidden;
	}

	.col-print-0 {
		width: 0 !important;
		padding: 0 !important;
	}

	.app-menu {
		display: none;
	}

	table {
		border-collapse: separate !important;
		border-spacing: 0 !important;
	}

	tr {
		top: 0 !important; /* reset lines position */
	}

	th.col-mois,
	td.col-mois,
	colgroup col.col-mois {
		min-width: 19mm !important;
		width: 19mm !important;
		max-width: 19mm !important;
	}

	th.clear-cell,
	td.clear-cell {
		background: transparent !important;
	}

	note-google {
		* {
			font-size: 2rem;
		}

		.print-cell-taller td {
			height: 1.5cm;
		}
	}

	preparation-objectifs-ca-annuel {
		* {
			font-size: 1.75rem;
		}

		.text-sm {
			font-size: 1.25rem !important;
		}

		.cell-spacer {
			width: 6rem;
		}
	}

	.print-surface-0 {
		background: var(--surface-0) !important;
	}

	.p-frozen-column {
		left: 0 !important;
	}

	.print-table-center table {
		margin: auto;
	}

	.print-table-w-full .p-datatable,
	.print-table-w-full table {
		width: 100%;
	}

	.print-center {
		margin: auto;
	}

	.print-justify-content-center {
		justify-content: center;
	}

	.print-no-max-w {
		max-width: unset;
	}

	.white-text-on-print {
		color: white !important;
	}

	.orange-text-on-print {
		color: orange !important;
	}

	.black-bg-on-print {
		background-color: black !important;
	}

	/* on masque les paginator */
	p-paginator {
		display: none;
	}

	/* pour que les tables scrollables soient affichées en entier */
	.p-datatable-flex-scrollable,
	.p-datatable-flex-scrollable .p-datatable-wrapper,
	.p-datatable-scrollable .p-datatable-wrapper {
		height: unset !important;
		max-height: unset !important;
	}

	@layer primeng {
		.table-qui-fait-quoi .p-tag {
			background: white !important;
			color: black !important;
			font-size: 12px !important;
		}
	}

	.bandeau-qui-fait-quoi {
		background: black;
		width: 75px;

		/* Texte */
		div {
			writing-mode: vertical-rl;
			color: white;
			text-orientation: upright;
		}
	}

}

@each $key, $val in $breakpoints {
	@media print and (min-width: #{$val}) {
		.#{$key + $separator} {
			&#{$prefix}col {
				flex-grow: 1;
				flex-basis: 0;
				padding: $gutter;
			}

			&#{$prefix}col-fixed {
				flex: 0 0 auto;
				padding: $gutter;
			}

			@each $col, $width in $grid-columns {
				&#{$prefix}#{$col} {
					flex: 0 0 auto;
					padding: $gutter;
					width: #{$width};
				}
			}
		}
	}
}
