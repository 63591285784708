/* You can add global styles to this file, and also import other style files */

@import 'styles/vars';
@import 'styles/primeflex';
@import 'styles/theme';
@import 'styles/base';
@import 'styles/buttons';
@import 'styles/cockpit';
@import 'styles/declaration';
@import 'styles/file-upload';
@import 'styles/inputtext';
@import 'styles/logo';
@import 'styles/menu';
@import 'styles/table';
@import 'styles/print';
@import 'styles/aviva';
@import 'styles/elton';

