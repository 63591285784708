bloc-valeurs {
	.p-datatable .p-datatable-wrapper {
		// overflow: hidden;
	}
}

bloc-panier {
	.p-datatable .p-datatable-tbody > tr.separator {
		background: transparent !important;

		td {
			border: none !important;
			padding: 0.25rem;
		}
	}

}

bloc-objectifs {
	.p-progressbar {
		height: 14px;
	}
}

bloc-ratios {
	display: block;
	position: relative;

	p-chart {
		display: block;
		max-width: 100%;
		width: 100%;

		canvas {
			max-width: 100%;
			max-height: 100%;
		}
	}

	.center-label {
		top: calc(50% - 0.5rem);
		width: 30%;
	}

	.center-label-past {
		font-size: 1.25rem;
	}

	.cockpit-ratio-annee-n {
		position: relative;
	}

	.cockpit-ratio-annee-n-1 {
		position: relative;
	}
}

.cockpit-large {
	font-weight: 600;
	font-size: 1.5rem;
}

.cockpit-semi-large {
	font-weight: 600;
	font-size: 1.25rem;
}
