:root {
  --blue-50: #f5f9ff;
  --blue-100: #d0e1fd;
  --blue-200: #abc9fb;
  --blue-300: #85b2f9;
  --blue-400: #609af8;
  --blue-500: #3b82f6;
  --blue-600: #326fd1;
  --blue-700: #295bac;
  --blue-800: #204887;
  --blue-900: #183462;
  --bluegray-50: #f7f9f9;
  --bluegray-100: #d9e0e3;
  --bluegray-200: #bbc7cd;
  --bluegray-300: #9caeb7;
  --bluegray-400: #7e96a1;
  --bluegray-500: #607d8b;
  --bluegray-600: #526a76;
  --bluegray-700: #435861;
  --bluegray-800: #35454c;
  --bluegray-900: #263238;
  --cyan-50: #f2fcfd;
  --cyan-100: #c2eff5;
  --cyan-200: #91e2ed;
  --cyan-300: #61d5e4;
  --cyan-400: #30c9dc;
  --cyan-500: #00bcd4;
  --cyan-600: #00a0b4;
  --cyan-700: #008494;
  --cyan-800: #006775;
  --cyan-900: #004b55;
  --gray-50: #FAFAFA;
  --gray-100: #F5F5F5;
  --gray-200: #EEEEEE;
  --gray-300: #E0E0E0;
  --gray-400: #BDBDBD;
  --gray-500: #9E9E9E;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --green-50: #f7faf5;
  --green-100: #dbe8cf;
  --green-200: #bed6a9;
  --green-300: #a1c384;
  --green-400: #85b15e;
  --green-500: #689f38;
  --green-600: #588730;
  --green-700: #496f27;
  --green-800: #39571f;
  --green-900: #2a4016;
  --indigo-50: #f6f7fc;
  --indigo-100: #d5d9ef;
  --indigo-200: #b3bae2;
  --indigo-300: #919cd5;
  --indigo-400: #707dc8;
  --indigo-500: #4e5fbb;
  --indigo-600: #42519f;
  --indigo-700: #374383;
  --indigo-800: #2b3467;
  --indigo-900: #1f264b;
  --orange-50: #fff8f3;
  --orange-100: #feddc7;
  --orange-200: #fcc39b;
  --orange-300: #fba86f;
  --orange-400: #fa8e42;
  --orange-500: #f97316;
  --orange-600: #d46213;
  --orange-700: #ae510f;
  --orange-800: #893f0c;
  --orange-900: #642e09;
  --pink-50: #fef6fa;
  --pink-100: #fad3e7;
  --pink-200: #f7b0d3;
  --pink-300: #f38ec0;
  --pink-400: #f06bac;
  --pink-500: #ec4899;
  --pink-600: #c93d82;
  --pink-700: #a5326b;
  --pink-800: #822854;
  --pink-900: #5e1d3d;
  --purple-50: #fbf7ff;
  --purple-100: #ead6fd;
  --purple-200: #dab6fc;
  --purple-300: #c996fa;
  --purple-400: #b975f9;
  --purple-500: #a855f7;
  --purple-600: #8f48d2;
  --purple-700: #763cad;
  --purple-800: #5c2f88;
  --purple-900: #432263;
  --red-50: #fff5f5;
  --red-100: #ffd0ce;
  --red-200: #ffaca7;
  --red-300: #ff8780;
  --red-400: #ff6259;
  --red-500: #ff3d32;
  --red-600: #d9342b;
  --red-700: #b32b23;
  --red-800: #8c221c;
  --red-900: #661814;
  --teal-50: #f2faf9;
  --teal-100: #c2e6e2;
  --teal-200: #91d2cc;
  --teal-300: #61beb5;
  --teal-400: #30aa9f;
  --teal-500: #009688;
  --teal-600: #008074;
  --teal-700: #00695f;
  --teal-800: #00534b;
  --teal-900: #003c36;
  --yellow-50: #fffcf5;
  --yellow-100: #fef0cd;
  --yellow-200: #fde4a5;
  --yellow-300: #fdd87d;
  --yellow-400: #fccc55;
  --yellow-500: #fbc02d;
  --yellow-600: #d5a326;
  --yellow-700: #b08620;
  --yellow-800: #8a6a19;
  --yellow-900: #644d12;
}

$prefix: '';
